import * as React from "react"

import { Container } from "../../components"
import { Category } from "../../section"
import Seo from "../../components/seo"

const MensCosmetics = () => (
  <Container>
    <Seo
      title="メンズコスメ"
      description="韓国や欧米では男も化粧するのが「当たり前」になりつつあります。人は男も女もキレイであるべきだと思います。しかし日本ではあまりメンズコスメの商品が売っていないのが現状です。ここでは私が実際に使ってみておすすめのメンズコスメを忖度なしの意見と共に紹介します。"
    />
    <Category
      Title="Men's Cosmetics"
      SubTitle="メンズコスメ"
      theCategory="mens-cosmetics"
    />
  </Container>
)

export default MensCosmetics
